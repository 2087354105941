import { cls, whisper } from '@/utils'
import useTemplateOfTheDay from '@/hooks/useTemplateOfTheDay'
import { useCachedTemplate } from '@/hooks/useTemplate'
import { useMemo } from 'react'
import EventCard from '../event-card'

export interface TemplateOfTheDayCardProps {
  className?: string
  source: string
}

export default function TemplateOfTheDayCard({ className, source }: TemplateOfTheDayCardProps) {
  const { data: templateOfTheDay, event } = useTemplateOfTheDay()
  const firstTemplate = templateOfTheDay?.templates[0] ?? null
  const firstTemplateId = firstTemplate?.template_id
  const { data: templateDetail } = useCachedTemplate(firstTemplateId)

  const tempEvent: haiper.MarketingEvent = useMemo(() => {
    return {
      id: 'template-of-the-day',
      title: templateDetail?.name ?? firstTemplate?.name ?? '',
      abstract: 'Template of the day',
      content: '',
      // FIXME: temp solution
      image: event?.image || templateDetail?.cover_image || '',
      mask_style: 'from-[#88837B]',
      start_time: '',
      end_time: '',
      action: {
        name: 'Start creating',
        type: 'open_url',
        payload: {
          url: `/template/${firstTemplateId}`,
        },
      },
    }
  }, [templateDetail, firstTemplate, firstTemplateId, event])

  return (
    <div className={cls('relative overflow-hidden rounded-xl max-h-full', className)} aria-label='template of the day'>
      <EventCard source={source} className={cls('rounded-xl overflow-hidden h-full max-h-full')} data={tempEvent} />
    </div>
  )
}
